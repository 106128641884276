<template>
  <b-row>
    <b-col sm="12" md="4">
      <app-collapse accordion type="margin">
        <app-collapse-item
          :class="'Bank' == appData.paytype ? 'btn-flat-primary' : null"
          :title="'Bank Details - ₹ ' + parseFloat(transactiondata.bankac || 0).toFixed(2)"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in transactiondata.bankdet"
              :key="'bankdet' + index"
              v-on:click="paytymemanage('Bank', item)"
              :active="
                item.id ==
                (appData.paytypedatax ? appData.paytypedatax.id : null)
                  ? 'active'
                  : null
              "
            >
              {{ item.data.holdername }} - {{ item.data.bankname }}<br />
              {{ item.data.accountnumber }} (₹ {{ parseFloat(item.data.balance || 0).toFixed(2) }})
              <br />
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
        <app-collapse-item
          :class="'Credit Cards' == appData.paytype ? 'btn-flat-primary' : null"
          :title="'Credit Card  - ₹ ' + parseFloat(transactiondata.cardac || 0).toFixed(2)"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in transactiondata.carddet"
              :key="'carddet' + index"
              v-on:click="paytymemanage('Credit Cards', item)"
              :active="
                item.id ==
                (appData.paytypedatax ? appData.paytypedatax.id : null)
                  ? 'active'
                  : null
              "
            >
              {{ item.data.holdername }} - {{ item.data.cardtype }}<br />
              {{ item.data.cardnumber }} (₹ {{ parseFloat(item.data.balance || 0).toFixed(2) }})
              <br />
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
        <app-collapse-item
          :class="'Cash' == appData.paytype ? 'btn-flat-primary' : null"
          :title="'Cash  - ₹ ' + (transactiondata.cashac || 0)"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in transactiondata.cashdet"
              :key="'cashdet' + index"
              v-on:click="paytymemanage('Cash', item)"
              :active="
                item.id ==
                (appData.paytypedatax ? appData.paytypedatax.id : null)
                  ? 'active'
                  : null
              "
            >
              {{ item.data.holdername }} - (₹ {{ parseFloat(item.data.balance || 0).toFixed(2) }})
              <br />
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
        <app-collapse-item
          :class="'Wallet' == appData.paytype ? 'btn-flat-primary' : null"
          :title="'Wallet  - ₹ ' + parseFloat(transactiondata.walletac || 0).toFixed(2)"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in transactiondata.walletdet"
              :key="'walletdet' + index"
              v-on:click="paytymemanage('Wallet', item)"
              :active="
                item.id ==
                (appData.paytypedatax ? appData.paytypedatax.id : null)
                  ? 'active'
                  : null
              "
            >
              {{ item.data.holdername }} - (₹ {{ parseFloat(item.data.balance || 0).toFixed(2) }})
              <br />
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
        <app-collapse-item
          :class="'Pay Later' == appData.paytype ? 'btn-flat-primary' : null"
          :title="'Pay Later  - ₹ ' + (transactiondata.paylaterac || 0)"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in transactiondata.paylaterdet"
              :key="'paylaterdet' + index"
              v-on:click="paytymemanage('Pay Later', item)"
              :active="
                item.id ==
                (appData.paytypedatax ? appData.paytypedatax.id : null)
                  ? 'active'
                  : null
              "
            >
              {{ item.data.holdername }} - (₹ {{ parseFloat(item.data.balance || 0).toFixed(2) }})
              <br />
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
      </app-collapse>
    </b-col>
    <b-col sm="12" md="8">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
                v-if="appData.paytypedatax"
              >
                <div class="float-right" v-if="edittype == 0">
                  <span class="m-0 me-2 mx-1">Single Transaction</span>
                  <b-form-checkbox
                    checked="true"
                    @change="clearupload"
                    v-model="singmult"
                    name="check-button"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                  <span class="m-0 mx-0">Multiple Transaction</span>
                </div>
                <div v-if="!singmult">
                  <div class="demo-inline-spacing">
                    <b-button
                    v-on:click="changetype('Income')"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="appData.transactiontype=='Income'?'success':'outline-success'"
                    >
                      Income
                    </b-button>
                    <b-button
                    v-on:click="changetype('Expense')"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      :variant="appData.transactiontype=='Expense'?'danger':'outline-danger'"
                    >
                      Expense
                    </b-button>
                  </div>
                  <b-row>
                    <b-col sm="12" md="6" class="mt-1">
                      <label> {{ $t("fields.category") }}</label>
                      <inputvselect
                        name="category"
                        :tooltip="
                          $t('fields.select') + ' ' + $t('fields.category')
                        "
                        :placeholder="
                          $t('fields.select') + ' ' + $t('fields.category')
                        "
                        :label="(reldata) => reldata.name"
                        :valuex="appData.expencesubid"
                        keys="key"
                        @updatedata="
                          (val) => {
                            managetransationval(val);
                          }
                        "
                        :options="categoryoptions"
                        id="categoryoptions"
                        validations="required"
                      ></inputvselect>
                    </b-col>
                    <b-col
                      v-if="
                        appData.expencesubid && subscriptionalldata.length > 0
                      "
                      sm="12"
                      md="6"
                      class="mt-1"
                    >
                      <label> {{ $t("fields.subcategory") }}</label>
                      <inputvselect
                        name="subcategory"
                        :tooltip="
                          $t('fields.select') + ' ' + $t('fields.subcategory')
                        "
                        :placeholder="
                          $t('fields.select') + ' ' + $t('fields.subcategory')
                        "
                        :label="(reldata) => reldata.name"
                        :valuex="appData.serviceproviderid"
                        keys="key"
                        @updatedata="
                          (val) => {
                            manageserviceprovider(val);
                          }
                        "
                        :options="subscriptionalldata"
                        id="subcategory"
                        validations="required"
                      ></inputvselect>
                    </b-col>

                    <b-col sm="12" md="6" class="mt-1">
                      <inputtext
                        name="amount"
                        :tooltip="
                          $t('fields.enter') + ' ' + $t('fields.amount')
                        "
                        :label="$t('fields.amount')"
                        :valuex="appData.amount"
                        @updatedata="
                          (val) => managefloat('amount', parseFloat(val))
                        "
                        validations="amount"
                      >
                      </inputtext>
                    </b-col>

                    <b-col sm="12" md="6" class="mt-1">
                      <inputtext
                        name="description"
                        :tooltip="
                          $t('fields.enter') + ' ' + $t('fields.description')
                        "
                        :label="$t('fields.description')"
                        :placeholder="
                          $t('fields.enter') + ' ' + $t('fields.description')
                        "
                        :valuex="appData.description"
                        @updatedata="(val) => (appData.description = val)"
                        validations=""
                      ></inputtext>
                    </b-col>
                    <b-col sm="12" md="6" class="mt-1">
                      <label> {{ $t("fields.date") }}</label>
                      <flat-pickr
                        v-model="appData.date"
                        :config="{
                          dateFormat: 'd-m-Y',
                          maxDate: today,
                        }"
                        placeholder="DD-MM-YYYY"
                        class="form-control"
                      />
                    </b-col>

                    <b-col sm="12" md="6" class="mt-1">
                      <inputtext
                        name="notes"
                        :tooltip="$t('fields.enter') + ' ' + $t('fields.notes')"
                        :label="$t('fields.notes')"
                        :placeholder="
                          $t('fields.enter') + ' ' + $t('fields.notes')
                        "
                        :valuex="appData.notes"
                        @updatedata="(val) => (appData.notes = val)"
                        validations=""
                      >
                      </inputtext>
                    </b-col>
                    <b-col sm="12" v-if="edittype == 0">
                      <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                      <b-row>
                        <b-col sm="12" md="6" lg="6">
                          <validation-provider
                            #default="validationContext"
                            name="Upload File"
                            rules=""
                          >
                            <b-form-group
                              :label="$t('fields.uploadfile')"
                              label-for="fileinput"
                            >
                              <b-form-file
                                accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                                id="fileinput"
                                name="Upload File "
                                :placeholder="$t('fields.uploadfile')"
                                drop-placeholder="Drop file here..."
                                @change="updateValue($event)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <b-col class="mt-4" sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="12" lg="12">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept=".xls,.xlsx"
                              id="fileinput"
                              name="Upload File "
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col sm="12">
                        Please use this file to
                        <a download :href="'https://userfrp.smartfrp.com/storage/templates/transactionupload.xlsx'"> download the template  </a>
                        for upload
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
                <b-col sm="12">
                  <div class="d-flex float-right mt-1">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="mr-1"
                      variant="secondary"
                      @click="$router.go(-1)"
                    >
                      <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                      <span class="align-middle"> {{ $t("fields.back") }}</span>
                    </b-button>
                    <b-button
                      v-if="edittype == 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      :to="{
                        name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                        params: { id: iddata },
                      }"
                    >
                      <feather-icon icon="EyeIcon" class="mr-25" />
                      {{ $t("fields.view") }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary                            "
                      class="mr-1"
                      type="submit"
                    >
                      <feather-icon icon="Edit3Icon" class="mr-25" />
                      {{ $t("fields.save") }}
                    </b-button>
                  </div>
                </b-col>
              </b-form>
              <div v-else class="h-100 align-middle text-center">
                <img
                  style="height: 150px"
                  :src="require('@/assets/images/illustration/Top.png')"
                />
                Please select a payment in the left column
              </div>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormCheckbox,
  BFormFile,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    BFormRadio,
    BFormCheckboxGroup,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "cashflow-transaction";
    const modulename = "Transaction";
    const modulenamesub = "transaction";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();
    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      transactiontype: "Income",
      amount: 0,
      categoryname: "",
      date: "",
      paytype: "",
      expencesubid: "",
      serviceproviderid: "",
      paytypedatax: "",
      description: "",
      expencesubserve: "",
      categoryoptions: "",
      categorynameval: "",
      subcategory: "",
      notes: "",
      selectedmanage: "",
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;
    var labelx = ref("");
    var singmult = ref(false);

    var transactiondata = ref({});
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/transactiondata")
      .then((response) => {
        transactiondata.value = response.data;
        getdatacheck(appData.value.paytypedatax, appData.value.paytype);
      });
    const mangepaytype = (val) => {
      appData.value.paytype = val;
      var temp = "";
      if (val && val == "Bank") {
        temp = "fetchbank";
        labelx.value = "bank";
      } else if (val && val == "Cash") {
        temp = "fetchwcash";
        labelx.value = "cash";
      } else if (val && val == "Wallet") {
        temp = "fetchwallets";
        labelx.value = "wallet";
      } else if (val && val == "Paylater") {
        temp = "fetchpaylater";
        labelx.value = "paylater";
      }
      if (temp) {
        paydataxopt(temp);
      }
    };
    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      if (!temp.paytype || !temp.paytypedatax) {
        alert("Please choose a payment method");
        return "";
      }
      if (
        temp.transactiontype == "Expense"
      ) {
        if(['Bank','Cash','Wallet'].includes(appData.value.paytype) && temp.paytypedatax.data.balance < temp.amount){
          alert("Entry declined due to insufficent funds.");
          return "";
        }
        else if(['Pay Later','Credit Cards'].includes(appData.value.paytype) && ((temp.paytypedatax.data.balance + temp.amount) < temp.paytypedatax.data.limit)){
          alert("Entry declined due to insufficent funds.");
          return "";
        }
      }

      let appdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        name: temp.paytype,
        transactiontype: temp.transactiontype || "Income",
        amount: temp.amount,
        date: temp.date,
        description: temp.description,
        paytype: temp.paytype,
        expencesubid: temp.expencesubid,
        categoryoptions: temp.categoryoptions ? temp.categoryoptions.name : "",
        serviceproviderid: temp.serviceproviderid || "",
        serviceprovider: temp.expencesubid
          ? temp.serviceprovider
            ? temp.serviceprovider.name
            : "" || ""
          : null,
        paytypedatax: temp.paytypedatax.id,
        subcategoryname: temp.subcategory ? temp.subcategory.name : null,
        subcategory: temp.subcategory ? temp.subcategory.id : null,
        categorynameval: temp.expencesubserve ? temp.expencesubserve.id : null,
        categoryname: temp.expencesubserve ? temp.expencesubserve.name : null,
        notes: temp.notes,
      };
      let metadataappdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        subcategoryname: temp.subcategory ? temp.subcategory.name : null,
        amount: temp.amount,
        datex: temp.date.split("-").reverse().join("-"),
        date: temp.date,
        paytype: temp.paytype,
        serviceproviderid: temp.serviceproviderid || "",
        serviceprovider: temp.expencesubid
          ? temp.serviceprovider
            ? temp.serviceprovider.name
            : "" || ""
          : null,
        paytypedatax: temp.paytypedatax.id,
        categoryoptions: temp.categoryoptions ? temp.categoryoptions.name : "",
        expencesubid: temp.expencesubid,
        name: temp.paytype,
        transactiontype: temp.transactiontype,
      };

      formData.append("name", temp.paytype);
      formData.append("customvalue", metadataappdatax.customvalue);
      formData.append("data", JSON.stringify(appdatax));
      formData.append("metadata", JSON.stringify(metadataappdatax));
      if (edittype == 0) {
        let file = temp.fileData.file;
        formData.append("file", file);
      }
      formData.append("status", 1);
      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.paytype,
          metadata: metadataappdatax,
        };
      }
      if (singmult.value) {
        storetype = "bulkaddtransaction";
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
            tempcatch = 1;
          })
          .catch((error) => {
            tempcatch = 1;
            console.log(error);

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };

    const categoryoptions = ref([]);
    const getexpenselist = () => {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetcheexpense", {
          type: appData.value.transactiontype,
        })
        .then((response) => {
            categoryoptions.value = response.data.data;
        });
    };

    getexpenselist()
    const subscriptionalldata = ref([]);
    const subscriptionDataget = () => {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchexpencesub", {
          id: appData.value.expencesubid,
        })
        .then((response) => {
          subscriptionalldata.value = response.data.data;
        });
    };

    const paytymemanage = (name, val) => {
      appData.value.paytype = name;
      appData.value.paytypedatax = val;
    };
    const getdatacheck = (id, opt) => {
      if (!transactiondata.hasOwnProperty("value")) {
        return 0;
      }
      if (opt == "Bank" && transactiondata.value.hasOwnProperty("bankdet")) {
        for (var i = 0; i < transactiondata.value.bankdet.length; i++) {
          if (transactiondata.value.bankdet[i].id == id) {
            paytymemanage(opt, transactiondata.value.bankdet[i]);
          }
        }
      } else if (
        opt == "Credit Cards" &&
        transactiondata.value.hasOwnProperty("carddet")
      ) {
        for (var i = 0; i < transactiondata.value.carddet.length; i++) {
          if (transactiondata.value.carddet[i].id == id) {
            paytymemanage(opt, transactiondata.value.carddet[i]);
          }
        }
      } else if (
        opt == "Cash" &&
        transactiondata.value.hasOwnProperty("cashdet")
      ) {
        for (var i = 0; i < transactiondata.value.cashdet.length; i++) {
          if (transactiondata.value.cashdet[i].id == id) {
            paytymemanage(opt, transactiondata.value.cashdet[i]);
          }
        }
      } else if (
        opt == "Wallet" &&
        transactiondata.value.hasOwnProperty("walletdet")
      ) {
        for (var i = 0; i < transactiondata.value.walletdet.length; i++) {
          if (transactiondata.value.walletdet[i].id == id) {
            paytymemanage(opt, transactiondata.value.walletdet[i]);
          }
        }
      } else if (
        opt == "Pay Later" &&
        transactiondata.value.hasOwnProperty("paylaterac")
      ) {
        for (var i = 0; i < transactiondata.value.paylaterac.length; i++) {
          if (transactiondata.value.paylaterac[i].id == id) {
            paytymemanage(opt, transactiondata.value.paylaterac[i]);
          }
        }
      }
    };
    const paydatax = ref([]);
    const paydataxopt = (type) => {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/" + type)
        .then((response) => {
          paydatax.value = response.data.data;
        });
    };
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }
          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          mangepaytype(appData.value.paytype);
          getdatacheck(appData.value.paytypedatax, appData.value.paytype);
          key = Math.random();
    getexpenselist()
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      mangepaytype,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      categoryoptions,
      subscriptionDataget,
      today,
      paytymemanage,
      tempcatch,
      paydatax,
      paydataxopt,
      labelx,
      transactiondata,
      subscriptionalldata,
      singmult,
      getexpenselist
    };
  },
  mounted() {},
  methods: {
    subcategorymanage(val) {
      this.appData.subcategory = val;
    },
    categoryval(val) {
      this.appData.expencesubserve = val;
      this.appData.categorynameval = val.id;
    },
    managetransationval(val) {
      if (val != this.appData.categoryoptions) {
        this.appData.expencesubid = val ? val.id : null;
        this.appData.categoryoptions = val;
        if (val) {
          this.subscriptionDataget();
        }
      }
      if (!val) {
        this.subscriptionalldata = [];
      }
    },
    changetype(val){
        this.appData.transactiontype=val
        this.getexpenselist()
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    manageserviceprovider(val) {
      if (val != this.appData.serviceprovider) {
        this.appData.serviceprovider = val;
        this.appData.serviceproviderid = val ? val.id : null;
      }
    },
    clearupload() {
      this.appData.fileData = {
        name: "",
        file: "",
        size: "",
        type: "",
      };
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
